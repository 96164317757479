.ReactModal__Overlay--after-open {
  z-index: 1055 !important;
  /* Asegúrate de que este valor sea mayor que el z-index de tu modal */
}

.ril-image-current {
  z-index: 1056 !important;
}

.draft-image {
  border: 3px dashed #000;
  padding: 5px;
  /*margin: 10px;*/
  display: inline-block;
  background-color: #f0f0f0;
  /* Opcional: Fondo gris claro */
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.2);
  /* Opcional: Sombra para dar efecto de elevación */
  border-radius: 10px;
  /* Opcional: Bordes redondeados */
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 1s forwards;
}

.player-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.9);
  /* Slightly darken the background */
  z-index: 1058 !important;
  /* Ensure it appears on top of the lightbox */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 90%;
  max-height: 90%;
}