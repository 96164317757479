.nav-select-ubication-order .nav-link.active {
  color: rgb(50, 169, 50) !important;
}

.nav-select-ubication-order .nav-link.active > .nav-no {
  border-color: rgb(50, 169, 50) !important;
  color: rgb(50, 169, 50) !important;
  box-shadow: rgba(50, 169, 50, 0.2) !important;
}

.nav-select-ubication-order .nav-item .nav-link.active .nav-no {
  box-shadow: 0 0 0 5px rgba(50, 169, 50, 0.2) !important;
}

.nav-select-ubication-order .nav-item .nav-link.active:after {
  background: rgb(50, 169, 50) !important;
}

.table-amount tbody:nth-child(even) {
  background: #dbf0f7;
}

.invoice-iva {
  background: #cc7722 !important;
  min-width: 20% !important;
}

.invoice-subtotal {
  background: #d0974d !important;
  min-width: 20% !important;
}

/*Scroll*/
.active-target-scroll {
  background-color: blue;
  /* Color azul al estar activo */
  animation: glow-target-scroll 0.5s ease-in-out;
  /* Duración de medio segundo y suavizado */
}

/* Estilos para revertir la animación al finalizar */
.active-target-scroll:after {
  transition: background-color 0.5s ease-in-out;
  /* Transición para revertir el color */
}

.active-target-scroll:hover::after {
  background-color: transparent;
  /* Revertir al estado original al finalizar la animación */
}

@keyframes glow-target-scroll {
  0% {
    background-color: blue;
    /* Color inicial (azul) */
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.8);
    /* Sombra suave azul */
  }

  50% {
    background-color: transparent;
    /* Cambia a transparente en el punto medio */
    box-shadow: none;
    /* Sin sombra */
  }

  100% {
    background-color: blue;
    /* Vuelve a azul al final */
    box-shadow: 0 0 10px rgba(0, 0, 255, 0.8);
    /* Sombra suave azul */
  }
}

/*EStilo pills OrderStatus*/
.nav-status-order-detail .nav-item .active {
  background: rgb(50, 169, 50) !important;
}

.nav-status-order-detail .nav.nav-wizards-2 .nav-item .nav-link.active {
  box-shadow: 0 0 0 4px rgba(50, 169, 50, 0.2) !important;
}

.nav-status-order-detail .nav.nav-wizards-2 .nav-item .nav-link.active:after {
  background: rgb(50, 169, 50) !important;
}

.nav-status-order-detail .nav-item .cancelled {
  background: rgb(130, 22, 20) !important;
  color: white !important;
}

.nav-status-order-detail .nav.nav-wizards-2 .nav-item .nav-link.cancelled {
  box-shadow: 0 0 0 4px rgba(169, 50, 50, 0.2) !important;
}

.nav-status-order-detail
  .nav.nav-wizards-2
  .nav-item
  .nav-link.cancelled:after {
  background: rgb(169, 58, 50) !important;
}

/*TIMELINE UBICACIONES*/
.timeline-locations .timeline .timeline-body {
  padding-top: 0rem;
  padding-left: 0.3rem;
}

.timeline-locations .timeline .timeline-content {
  border: none !important;
  margin-left: 8%;
}

.timeline-locations .timeline .timeline-content:before {
  border: none !important;
}

.timeline-locations .timeline .timeline-content:after {
  border: none !important;
}

.timeline-locations .timeline .timeline-item {
  padding: 0 !important;
}

.timeline-locations .timeline:before {
  width: 3px !important;
  left: 5% !important;
}

.timeline-locations .timeline .timeline-icon {
  left: 0% !important;
  top: 7px;
}

.timeline-locations .timeline .timeline-icon a {
  border: none;
  line-height: 20px;
  background: #595959;
}

.invoice .invoice-price .invoice-price-right {
  align-items: self-start !important;
}

.was-validated .input-group > .form-control:not(:focus):invalid,
.input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 0 !important;
}

/*Se coloca debido a que los input gropu se encimaban sobre los select debido a los z-index*/
.input-group .btn {
  z-index: 0  !important;
}

.nav {
  --bs-nav-link-padding-x: 0.5rem !important;
}