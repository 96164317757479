:root {
  --triple-toggle-width: 6.01em;
  --triple-toggle-height: 1.894em;
}

input[type="radio"] {
  display: none;
}

.triple-toggle-switch-label {
  --color: black;
  --z-index: 10;
  cursor: pointer;
}

h4 {
  margin: 0;
  font-weight: 500;
}

.black-font {
  color: black !important;
}

.main-container {
  display: inline-block;
  vertical-align: middle;
  width: 19em;
  height: 1.8944em;
  border-radius: 6.3296em;
  /* background-color: #4d4d4d; */
  background-color: lightgray;
  position: relative;
}

.switch {
  height: 1.515em;
  width: 6.329em;
  background-color: #4285f4;
  border-radius: 6.329em;
  position: absolute;
  left: 0.19em;
  top: 0.19em;
  transition: ease-in;
}

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}

.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.switch.left-position {
  left: 0.19em;
}

.left-label {
  position: absolute;
}

.left-check+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

.left-check:checked+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

@keyframes leftToCenter {
  from {
    left: 0.19em;
  }

  to {
    left: 0.19em;
  }
}

@keyframes leftToRight {
  from {
    left: 0.19em;
  }

  to {
    left: 4.8568em;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 6.329em;
}

.center-label {
  position: absolute;
  left: 6.329em;
}

.center-check+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

.center-check:checked+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

@keyframes centerToLeft {
  from {
    left: 2.568em;
  }

  to {
    left: 0.19em;
  }
}

@keyframes centerToRight {
  from {
    left: 2.568em;
  }

  to {
    right: .0568em;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 12.48em;
}

.right-label {
  position: absolute;
  right: 0.122em;
}

.right-check+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

.right-check:checked+label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--triple-toggle-height);
  width: var(--triple-toggle-width);
  border-radius: 6.329em;
}

@keyframes rightToLeft {
  from {
    left: 4.8568em;
  }

  to {
    left: 0.19em;
  }
}

@keyframes rightToCenter {
  from {
    left: 4.8568em;
  }

  to {
    right: 2.568em;
  }
}